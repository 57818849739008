import { ISerializableFilter } from '@portal-shared/app/interfaces/serializable-filter.interface';
import Dexie, { Table } from 'dexie';

export class DomainDatabase extends Dexie {
  protected static instance?: DomainDatabase;

  public filters!: Table<ISerializableFilter<unknown>, string>;

  protected get stores(): { [_: string]: string } {
    return {
      filters: 'name, value'
    };
  }

  protected constructor(domainKey: string) {
    super(domainKey);
    this.version(1).stores(this.stores);
    this.version(2)
      .stores(this.stores)
      .upgrade(trans => trans.table('filters').clear());
  }

  public static initialize(domainKey: string) {
    if (!this.instance) {
      this.instance = new DomainDatabase(domainKey);
    }
    return this.instance;
  }

  public static getInstance() {
    if (!this.instance) {
      throw Error('Initialize the domain database before using it.');
    }
    return this.instance;
  }
}
